import { Component, ViewEncapsulation, inject, signal } from '@angular/core';
import {
	MAT_SNACK_BAR_DATA,
	MatSnackBarRef,
} from '@angular/material/snack-bar';
import { NotificationData, NotificationType } from '@app/core/types';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
	selector: 'app-notification',
	standalone: true,
	imports: [TranslatePipe],
	templateUrl: './notification.component.html',
	encapsulation: ViewEncapsulation.None,
})
export class NotificationComponent {
	#data = inject<NotificationData>(MAT_SNACK_BAR_DATA);
	#ref = inject(MatSnackBarRef<NotificationComponent>);

	statusClasses = signal({
		success: 'text-green-600',
		info: 'text-blue-600',
		warning: 'text-red-600',
		error: 'text-red-600',
	}).asReadonly();
	header = signal({
		success: 'notification.header.success',
		info: 'notification.header.info',
		warning: 'notification.header.warning',
		error: 'notification.header.error',
	}).asReadonly();

	get message(): string {
		return this.#data.message;
	}

	get type(): NotificationType {
		return this.#data.type;
	}

	get statusClass() {
		return this.statusClasses()[this.#data.type];
	}

	close() {
		this.#ref.dismiss();
	}
}
