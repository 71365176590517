import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import {
	ApiEndpoint,
	ApiResponse,
	BillingType,
	Client,
	Conversation,
	CreateMessage,
	CreateNewChatPayload,
	MessageHistory,
	PaymentHistory,
	QueryPagination,
} from '@app/core/types';
import { environment } from '@app/env';
import * as api from '@stripe/stripe-js/dist/api/index';

@Injectable({ providedIn: 'root' })
export class ApiService {
	#http = inject(HttpClient);

	lastChats() {
		return this.#http.get<ApiResponse<Conversation[]>>(
			`${environment.CHATGPT_API_URL}${ApiEndpoint.Conversation}`,
			{
				params: {
					take: 5,
				},
			}
		);
	}

	getClients() {
		return this.#http.get<ApiResponse<Client[]>>(
			`${environment.MAIN_API_URL}${ApiEndpoint.AllClients}`,
			{
				params: {
					withRelations: true,
				},
			}
		);
	}

	createChat(data: CreateNewChatPayload) {
		return this.#http.post<ApiResponse<Conversation>>(
			`${environment.CHATGPT_API_URL}${ApiEndpoint.Conversation}`,
			data
		);
	}

	getConversation(conversationId: string) {
		return this.#http.get<ApiResponse<Conversation>>(
			`${environment.CHATGPT_API_URL}${ApiEndpoint.Conversation}/${conversationId}`
		);
	}

	createMessage(conversationId: string, data: CreateMessage) {
		const endpoint = `${environment.CHATGPT_API_URL}${ApiEndpoint.Conversation}/${conversationId}${ApiEndpoint.Message}`;
		return this.#http.post<ApiResponse<MessageHistory>>(endpoint, data);
	}

	getPaginatedChats(params: QueryPagination) {
		return this.#http.get<ApiResponse<Conversation[]>>(
			`${environment.CHATGPT_API_URL}${ApiEndpoint.Conversation}`,
			{
				params: { ...params },
			}
		);
	}

	removeConversation(id: string) {
		return this.#http.delete<ApiResponse<null>>(
			`${environment.CHATGPT_API_URL}${ApiEndpoint.Conversation}/${id}`
		);
	}

	createPaymentIntent(payload: { amount: number; billing: BillingType }) {
		return this.#http.post<ApiResponse<any>>(
			`${environment.CHATGPT_API_URL}${ApiEndpoint.PaymentIntent}`,
			payload
		);
	}

	savePayment(payload: {
		amount: number;
		stripeResponse: api.PaymentIntent;
		billing: BillingType;
	}) {
		return this.#http.post<ApiResponse<PaymentHistory>>(
			`${environment.CHATGPT_API_URL}${ApiEndpoint.SavePayment}`,
			payload
		);
	}
}
