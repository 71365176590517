import { TitleCasePipe } from '@angular/common';
import { Injectable, inject } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { Seo } from '@app/core/types';
import { environment } from '@app/env';

@Injectable({ providedIn: 'root' })
export class SeoService {
	#meta = inject(Meta);
	#title = inject(Title);

	setTitle(title: string) {
		const titleCase = new TitleCasePipe().transform(String(title));
		this.#title.setTitle(`${titleCase} | ${environment.DEFAULT_TITLE}`);
	}

	addTag(tag: MetaDefinition) {
		this.#meta.addTag(tag, true);
	}

	updateTag(tag: MetaDefinition) {
		this.#meta.updateTag(tag);
	}

	setMetaData(seo: Seo) {
		this.#meta.updateTag({
			name: 'description',
			content: seo?.metaDescription ?? '',
		});
		this.#meta.updateTag({ name: 'keywords', content: seo?.keywords ?? '' });
	}
}
