import { Injectable, inject } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NotificationComponent } from '@app/core/components/notification';
import { NotificationType } from '@app/core/types';

@Injectable({ providedIn: 'root' })
export class NotifyService {
	#snackBar = inject(MatSnackBar);

	notify(message: string, type: NotificationType, duration = 2500): void {
		this.#snackBar.openFromComponent(NotificationComponent, {
			duration,
			horizontalPosition: 'end',
			verticalPosition: 'top',
			panelClass: 'custom-notification-container',
			data: { message, type },
		});
	}
}
