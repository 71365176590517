import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable, PLATFORM_ID, inject, signal } from '@angular/core';
import { ApiEndpoint, ApiResponse, StorageUser, User } from '@app/core/types';
import { environment } from '@app/env';
import { WA_WINDOW } from '@ng-web-apis/common';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class AuthService {
	#http = inject(HttpClient);
	#platform = inject(PLATFORM_ID);
	#window = inject(WA_WINDOW);
	#cookies = inject(SsrCookieService);

	#authUser = signal<StorageUser | null>(null);
	#authJWT = signal<string | null>(null);

	init() {
		if (isPlatformBrowser(this.#platform)) {
			this.loadAuthUser();
			this.loadJWT();
			this.#authUser() === null || this.#authJWT() === null
				? this.logout()
				: this.checkSession();
		}
	}

	get isAuth(): boolean {
		return !!this.#authJWT() && !!this.#authUser();
	}

	get JWT(): string | null {
		return this.#authJWT();
	}

	get authUser(): any | null {
		return this.#authUser();
	}

	get isAdmin(): boolean {
		return this.#authUser()?.isAdmin ?? false;
	}

	get subscription() {
		return this.#authUser()?.subscriptions;
	}

	set updateUser(value: any | null) {
		if (isPlatformBrowser(this.#platform)) {
			localStorage.setItem(environment.AUTH_USER_KEY, JSON.stringify(value));
			this.#authUser.set(value);
		}
	}

	set updateJWT(value: string | null) {
		if (isPlatformBrowser(this.#platform)) {
			localStorage.setItem(environment.AUTH_JWT_KEY, <string>value);
			this.#authJWT.set(value);
		}
	}

	loadAuthUser() {
		if (this.#cookies.check(environment.AUTH_USER_COOKIE)) {
			this.updateUser = JSON.parse(
				<string>this.#cookies.get(environment.AUTH_USER_COOKIE)
			);
		} else if (localStorage.getItem(environment.AUTH_USER_KEY)) {
			this.updateUser = JSON.parse(
				<string>localStorage.getItem(environment.AUTH_USER_KEY)
			);
		} else {
			this.updateUser = null;
		}
	}

	loadJWT() {
		if (this.#cookies.check(environment.AUTH_JWT_COOKIE)) {
			this.updateJWT = this.#cookies.get(environment.AUTH_JWT_COOKIE);
		} else if (localStorage.getItem(environment.AUTH_JWT_KEY)) {
			this.updateJWT = <string>localStorage.getItem(environment.AUTH_JWT_KEY);
		} else {
			this.updateJWT = null;
		}
	}

	logout() {
		this.updateJWT = null;
		this.updateUser = null;
		this.#window.location.href = `${environment.LOGIN_URL}?returnUrl=${environment.CALLBACK_URL}&callback=external`;
	}

	checkSession() {
		this.#http
			.get<ApiResponse<StorageUser>>(
				`${environment.MAIN_API_URL}${ApiEndpoint.CheckSession}`
			)
			.subscribe({
				next: res =>
					res.status !== 'success' ? this.logout() : this.fetchMeData(),
				error: () => this.logout(),
			});
	}

	fetchMeData() {
		this.#http
			.get<ApiResponse<User>>(`${environment.CHATGPT_API_URL}${ApiEndpoint.Me}`)
			.subscribe(res1 => {
				if (res1.status === 'success')
					this.updateUser = {
						...this.#authUser(),
						...res1.data,
					};
			});
	}

	checkSubscription() {
		return this.#http
			.get<ApiResponse<void>>(
				`${environment.CHATGPT_API_URL}${ApiEndpoint.CheckSubscription}`
			)
			.pipe(map(res => res.status === 'success'));
	}
}
