import { AbstractControl, ValidationErrors } from '@angular/forms';
import { ApiResponse, ApiSuccessResponse } from '@app/core/types';
import { Observable, UnaryFunction, filter, pipe } from 'rxjs';

export function filterSuccess<T>(): UnaryFunction<
	Observable<ApiResponse<T>>,
	Observable<ApiSuccessResponse<T>>
> {
	return pipe(
		filter((response: ApiResponse<T>) => response.status === 'success')
	);
}

export function requiredStringValidator(
	control: AbstractControl
): ValidationErrors | null {
	const value =
		typeof control.value === 'string' ? control.value?.trim() : control.value;
	return value === '' ? { required: true } : null;
}
