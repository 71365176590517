{
  "name": "chatgpt-app",
  "version": "0.18.0",
  "description": "ChatGPT App as a service of JFabaNotes App",
  "author": {
    "name": "Yohan González Almaguer",
    "email": "yohan.gonzalez.almaguer@gmail.com",
    "url": "https://www.linkedin.com/in/yohan-gonzález-almaguer/"
  },
  "repository": {
    "type": "git",
    "url": "https://github.com/ZahiriNatZuke/chatgpt-app.git"
  },
  "license": "UNLICENSED",
  "private": true,
  "keywords": [
    "chatgpt",
    "openai",
    "api",
    "nodejs",
    "typescript",
    "angular",
    "ssr",
    "frontend",
    "app"
  ],
  "scripts": {
    "ng": "ng",
    "start": "ng serve --port 4300",
    "build": "ng build && pnpm sentry:sourcemaps",
    "watch": "ng build --watch --configuration development",
    "serve:ssr:chatgpt-app": "node dist/chatgpt-app/server/server.mjs",
    "sentry:sourcemaps": "sentry-cli sourcemaps inject --org znz-projects --project jfaba-notes-ai-app ./dist && sentry-cli sourcemaps upload --org znz-projects --project jfaba-notes-ai-app ./dist",
    "format": "biome format --fix",
    "lint": "biome check --fix --unsafe",
    "precommit": "pnpm run lint && pnpm run format",
    "prepare": "husky"
  },
  "dependencies": {
    "@angular/animations": "^18.2.12",
    "@angular/cdk": "^18.2.14",
    "@angular/common": "^18.2.12",
    "@angular/compiler": "^18.2.12",
    "@angular/core": "^18.2.12",
    "@angular/forms": "^18.2.12",
    "@angular/material": "^18.2.14",
    "@angular/platform-browser": "^18.2.12",
    "@angular/platform-browser-dynamic": "^18.2.12",
    "@angular/platform-server": "^18.2.12",
    "@angular/router": "^18.2.12",
    "@angular/ssr": "^18.2.12",
    "@ng-web-apis/common": "^4.11.1",
    "@ng-web-apis/intersection-observer": "^4.11.1",
    "@ngrx/effects": "^18.1.1",
    "@ngrx/operators": "^18.1.1",
    "@ngrx/router-store": "^18.1.1",
    "@ngrx/signals": "^18.1.1",
    "@ngrx/store": "^18.1.1",
    "@ngx-translate/core": "^16.0.3",
    "@ngx-translate/http-loader": "^16.0.0",
    "@sentry/angular": "^8.39.0",
    "@sentry/cli": "^2.38.2",
    "@stripe/stripe-js": "^4.10.0",
    "dotenv": "^16.4.5",
    "express": "^4.21.1",
    "lodash-es": "^4.17.21",
    "logrocket": "^9.0.0",
    "lucide": "^0.460.0",
    "lucide-angular": "^0.460.0",
    "marked": "^12.0.2",
    "ngx-cookie-service-ssr": "^18.0.0",
    "ngx-markdown": "^18.1.0",
    "ngx-socket-io": "^4.7.0",
    "ngx-stripe": "^18.1.0",
    "rxjs": "~7.8.1",
    "tslib": "^2.8.1",
    "zod": "^3.23.8",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.12",
    "@angular/cli": "^18.2.12",
    "@angular/compiler-cli": "^18.2.12",
    "@biomejs/biome": "^1.9.4",
    "@tailwindcss/typography": "^0.5.15",
    "@types/express": "^5.0.0",
    "@types/lodash-es": "^4.17.12",
    "@types/node": "^22.9.1",
    "autoprefixer": "^10.4.20",
    "daisyui": "^4.12.14",
    "husky": "^9.1.7",
    "postcss": "^8.4.49",
    "tailwindcss": "^3.4.15",
    "typescript": "~5.5.4"
  }
}
