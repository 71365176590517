import { Languages } from '@app/core/types';

export const environment = {
	PRODUCTION: true,
	APP_VERSION: `chatgpt-app@${require('../../package.json').version}`,
	CHATGPT_API_URL: 'https://ai-api.jfabanotes.com/api',
	MAIN_API_URL: 'https://api.jfabanotes.com/api/v1',
	DEFAULT_TITLE: 'JFaba Notes AI',
	DEFAULT_LANGUAGE: Languages.English,
	AUTH_USER_KEY: 'X-APP-AUTH-USER',
	AUTH_JWT_KEY: 'X-APP-AUTH-JWT',
	LANGUAGE_KEY: 'X-APP-LANGUAGE',
	THEME_KEY: 'X-APP-THEME',
	AUTH_USER_COOKIE: 'auth_user',
	AUTH_JWT_COOKIE: 'auth_token',
	MAIN_DOMAIN: 'https://www.jfabanotes.com',
	LOGIN_URL: 'https://www.jfabanotes.com/auth/login',
	CALLBACK_URL: 'https://ai.jfabanotes.com/home',
	SOCKET_IO_URL: 'https://ai-api.jfabanotes.com',
	CDN_URL: 'https://cdn.jfabanotes.com',
	STRIPE_KEY_PUBLIC:
		'pk_live_51MANK6AMIdtLpmvFRXxP22uFAVvjgkpNLPIIlnsUpOoDr10csMCLbLIx8pNFFpbM7pUfZ8jQhqvUgj391biJYnQG00yqXM5A98',
};
