import { Injectable } from '@angular/core';
import { AuthService } from '@app/core/services';
import { environment } from '@app/env';
import { Socket } from 'ngx-socket-io';

@Injectable({ providedIn: 'root' })
export class SocketService extends Socket {
	constructor(private auth: AuthService) {
		super({
			url: environment.SOCKET_IO_URL,
			options: {
				path: '/ws-conversation',
				reconnectionAttempts: 5,
				reconnectionDelay: 1000,
				reconnectionDelayMax: 5000,
				transports: ['websocket'],
				withCredentials: true,
				query: {
					authorization: `Bearer ${auth.JWT}`,
				},
			},
		});
	}
}
