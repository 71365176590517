export enum ApiEndpoint {
	// Main JFaba Note API
	CheckSession = '/auth/check-session',
	AllClients = '/client/all',

	// ChatGPT Service API
	Me = '/auth/me',
	CheckSubscription = '/auth/check-subscription',
	Conversation = '/conversation',
	Message = '/message',
	PaymentIntent = '/stripe/payment-intent',
	SavePayment = '/stripe/save-payment',
}
