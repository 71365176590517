import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
	Injectable,
	PLATFORM_ID,
	RendererFactory2,
	inject,
	signal,
} from '@angular/core';
import { Languages } from '@app/core/types';
import { environment } from '@app/env';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class LanguageService {
	#translateService = inject(TranslateService);
	#document = inject(DOCUMENT);
	#htmlElement = this.#document.querySelector('html');
	#renderer2 = inject(RendererFactory2).createRenderer(this.#document, null);
	#platform = inject(PLATFORM_ID);

	languageSignal = signal<Languages>(Languages.English);

	init() {
		if (isPlatformBrowser(this.#platform)) {
			if (localStorage.getItem(environment.LANGUAGE_KEY))
				this.setLang(<Languages>localStorage.getItem(environment.LANGUAGE_KEY));
			else this.setLang(Languages.English);
		}
	}

	setLang(lang: Languages) {
		localStorage.setItem(environment.LANGUAGE_KEY, lang);
		this.#translateService.use(lang);
		this.languageSignal.set(lang);
		this.#renderer2.setAttribute(this.#htmlElement, 'lang', lang);
	}
}
